/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~swiper/swiper-bundle.min.css";
@import "app/theme/styles/base";
@import "app/theme/styles/admin";
@import "app/theme/styles/theme";
@import "app/theme/styles/gradients";
@import "app/theme/styles/rtl";
@import "bootstrap/dist/css/bootstrap.css";

.brand-link {
  line-height: 2.5;
  // background-color: #fff;
  font-size: 1rem;
  .brand-image {
    // margin-top: 2px;
    max-height: 42px;
    width: 42px;
    background-color: white;
  }
  span {
    color: white;
    margin-left: 5px;
  }
}

a {
  text-decoration: none;
}

.mat-simple-snackbar {
  justify-content: center !important;
}

.cdk-global-overlay-wrapper {
  display: grid !important;
  place-items: center !important;
}

.dialog-responsive {
  width: 60vw;
}

@media only screen and (max-width: 640px) {
  .dialog-responsive {
    width: 100vw;
    min-width: 100vw;

    // height: 80vh !important;
    // max-height: 100vh !important;
    // margin: 0;
    // padding: 0;
    position: relative !important;

  }
  .dialog-responsive .mat-dialog-container {
    margin-top: 3;
    margin-bottom: 3;
    padding-top: 3;
    padding-bottom: 3;
  }
  .dialog-responsive .mat-dialog-content {
    max-height: 79vh;
  }
}
